* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Lato, sans-serif;
  font-size: 18px;
}

a {
  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
}

.inline-list {
  text-decoration: none;
}

.inline-list li {
  padding-right: 15px;
  display: inline-block;
}

.inline-list li a {
  color: #ffffffb3;
  text-decoration: none;
}

section {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
}

.float-right {
  float: right;
}

.hidden {
  display: none;
}

.link, .link:hover {
  color: #fff;
  text-decoration: none;
}

.lds-roller {
  width: 64px;
  height: 64px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 32px 32px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: #000;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu {
  color: #fff;
  text-transform: uppercase;
  z-index: 2000;
  background: linear-gradient(#0000001a, #0000 100%);
  width: 100%;
  height: 70px;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.menu--active {
  color: #000;
  background-color: #fff;
}

.menu__wrapper {
  justify-content: space-between;
  display: flex;
}

.menu__logo {
  margin-top: 6px;
  font-family: Knewave, cursive;
  font-size: 35px;
}

@media only screen and (width <= 768px) {
  .menu__logo {
    margin-top: 12px;
    margin-left: 10px;
    font-size: 30px;
  }
}

@media only screen and (width >= 769px) and (width <= 991px) {
  .menu__logo {
    margin-top: 12px;
    margin-left: 10px;
    font-size: 30px;
  }
}

@media only screen and (width >= 992px) {
  .menu__logo {
    font-size: 35px;
  }
}

.menu__logo a {
  text-decoration: none;
}

.menu__logo--light a {
  color: #fff;
}

.menu__logo--dark a {
  color: #000;
}

.menu__items {
  margin-top: 15px;
}

.menu__items ul {
  text-decoration: none;
}

.menu__items li {
  display: inline-block;
}

.menu__link {
  letter-spacing: .5px;
  color: #ffffffb3;
  margin-right: 10px;
  font-size: .7em;
  text-decoration: none;
  transition: all .5s;
}

.menu__link:hover {
  color: #fff;
  opacity: 1;
  text-decoration: none;
}

.menu__link--active {
  font-weight: 700;
}

.menu__link--dark {
  color: #000000b3;
}

.menu__link--dark:hover {
  color: #000;
}

.menu__hamburger {
  margin-top: 15px;
  margin-right: 15px;
  position: absolute;
  top: 5px;
  right: 15px;
}

.menu__mobile-button {
  float: right;
  background: none;
  border: none;
}

.mobile-menu {
  text-align: center;
  z-index: 9999;
  opacity: 0;
  background-color: #000c;
  width: 0;
  height: 0;
  padding: 0;
  transition: all .3s;
  position: fixed;
  top: 20px;
  left: 0;
  overflow: hidden;
}

.mobile-menu.active {
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
}

.mobile-menu__wrapper {
  text-transform: uppercase;
  height: 100%;
  margin-top: 60px;
  font-family: Roboto, monospace;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  overflow: auto;
}

.mobile-menu__wrapper ul {
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  margin: 0;
  padding: 30px 0;
  display: flex;
}

.mobile-menu__item {
  padding: 6px 0;
  list-style: none;
}

.mobile-menu__link {
  color: #fff;
  padding: 11px;
  display: inline-block;
  position: relative;
}

.mobile-menu__link:hover {
  opacity: 1;
  text-decoration: none;
}

.mobile-menu__link:after {
  background: #fff;
  bottom: 14px;
  right: 1px;
}

.mobile-menu__close {
  color: #fff;
  background-color: #0000;
  border: none;
  transition: all .3s;
  position: absolute;
  top: 25px;
}

.btn-menu {
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #fffc;
  width: 100px;
  margin-left: 15px;
  padding: 10px 5px;
  font-size: .7em;
  font-weight: 500;
}

.btn-login {
  background-color: #4a90e2;
  border-color: #4a90e2;
}

.btn-login:hover {
  color: #fff;
  background-color: #619de2;
  border-color: #467dbe;
  transition: all .1s;
  transform: translateY(-2px);
}

.btn-signup {
  background-color: #0000;
  border-color: #ffffff80;
  box-shadow: 0 0 2px 2px #619de280;
}

.btn-signup:hover {
  color: #fff;
  border-color: #fff;
  transition: all .1s;
  transform: translateY(-2px);
}

.btn-signup--active {
  color: #000;
  border-color: #00000080;
}

.btn-login-header {
  letter-spacing: .5px;
  width: 250px;
  font-size: .7em;
  font-weight: 500;
  box-shadow: -1px -1px 1px 1px #0000001a;
}

header {
  background-image: linear-gradient(#000, #619de21a 100%), url("rink.4cebb577.jpg");
  background-position: 10% 60%;
  background-size: cover;
  height: 90vh;
}

.schedule-header {
  height: 50vh;
}

.welcome {
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 33vh;
}

.welcome--schedule {
  padding-top: 18vh;
}

.welcome__header {
  font-family: Roboto;
  font-weight: 300;
}

@media only screen and (width <= 768px) {
  .welcome__header .welcome__dash {
    display: none;
  }
}

@media only screen and (width >= 769px) and (width <= 991px) {
  .welcome__header .welcome__dash {
    display: none;
  }
}

.welcome__animation {
  color: #80bafd;
  white-space: nowrap;
  border-right: .15em solid #80bafd;
  margin: 0 auto;
  animation: 3.5s steps(30, end) typing, .7s step-end infinite blink-caret;
  overflow: hidden;
}

@media only screen and (width <= 768px) {
  .welcome__animation {
    display: none;
  }
}

@media only screen and (width >= 769px) and (width <= 991px) {
  .welcome__animation {
    display: none;
  }
}

.welcome__description {
  width: 60%;
  margin: 25px auto 0;
  font-weight: 300;
}

@media only screen and (width <= 768px) {
  .welcome__description {
    width: 80%;
    font-size: .7em;
  }
}

@media only screen and (width >= 769px) and (width <= 991px) {
  .welcome__description {
    width: 80%;
    font-size: .7em;
  }
}

.welcome__login {
  margin-top: 40px;
}

@media only screen and (width <= 768px) {
  .welcome__login {
    display: none;
  }
}

.welcome__signup {
  margin-top: 12px;
  font-size: .6em;
  display: block;
}

.welcome__signup a {
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: #0000;
  }

  50% {
    border-width: 2px;
    border-color: #80bafd;
  }
}

.feature {
  color: #252525;
  background: #fff;
  border: 1px solid #0003;
  border-radius: 5px;
  min-height: 220px;
  margin-bottom: 20px;
  padding: 1.7em 1.9em .5em 2em;
  display: flex;
  position: relative;
  overflow: hidden;
}

.feature__body {
  width: 75%;
  padding-top: 5px;
  line-height: 1.2em;
}

.feature__icon {
  width: 25%;
  height: 80%;
  padding-top: 5px;
  padding-right: 20px;
}

.feature__title {
  font-weight: 500;
}

.feature__description {
  color: #4b4b4be6;
  font-size: .7em;
}

.players {
  color: #fff;
  background-color: #fff;
  background-image: linear-gradient(#0009, #0009), url("players.de5da108.jpg");
  background-position: 100%;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  padding: 100px 0;
  font-family: Lato, sans-serif;
  font-weight: 300;
}

.players__description {
  width: 50%;
  margin: 0 auto;
}

footer {
  color: #fff;
  text-transform: uppercase;
  background: #181818;
  height: 150px;
  padding: 0;
  font-family: Rubik, sans-serif;
  font-size: .8em;
  font-weight: 500;
}

.footer-large {
  padding-top: 50px;
}

.copyright {
  font-size: .6em;
}

@media only screen and (width <= 768px) {
  table {
    font-size: 9px;
  }
}
/*# sourceMappingURL=index.4c5275c1.css.map */
