@mixin buttonHover {
    transition: all .1s ease;
    transform: translateY(-2px);
    color: $white;
}

/*************************************
*** Buttons
**************************************/
.btn-menu {
    margin-left: 15px;
    width: 100px;
    font-weight: 100;
    font-size: 0.7em;
    padding: 10px 5px;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: $s-off-white;
}

.btn-login {
    background-color: $rd-dark-blue;
    border-color: $rd-dark-blue;
    &:hover {
        background-color:rgb(97, 157, 226);
        border-color: rgb(70, 125, 190);
        @include buttonHover;
    }
}

.btn-signup {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 2px 2px rgba(97, 157, 226, 0.5);

    &:hover {
        border-color: $white;
        @include buttonHover;
    }

    &--active {
        color: black;
        border-color: rgba(0, 0, 0, 0.5);
    }
}

.btn-login-header {
    width: 250px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: .8em;
    box-shadow: -1px -1px 1px 1px rgba(0, 0, 0, .1);
    font-size: 0.7em;
}