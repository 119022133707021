$font-stack: 'Lato', sans-serif;
$font-logo: 'Knewave', cursive;
$white: #fff;
$off-white: rgba(255,255, 255, 0.7);
$s-off-white: rgba(255,255, 255, 0.8);
$rd-blue: rgb(128, 186, 253);
$rd-dark-blue: rgb(74, 144, 226);

$break-small: 768px;
$break-large: 992px;

@mixin respond-to($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == medium-screens {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == wide-screens {
        @media only screen and (min-width: $break-large) { @content; }
    }
}

/*************************************
*** BASE
**************************************/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    font-family: $font-stack;
    font-size: 18px;
}

a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 300;
}

.inline-list {
    text-decoration: none;

    li {
        display: inline-block;
        padding-right: 15px;

        a {
            color: $off-white;
            text-decoration: none;
        }
    }
}

section {
    padding-top: 50px;
    background: #fafafa;
    padding-bottom: 50px;
}

.float-right {
    float: right;
}

.hidden {
    display: none;
}

.link {
    color: $white;
    text-decoration: none;

    &:hover {
        color: $white;
        text-decoration: none;
    }
}

/*************************************
*** Import other files
**************************************/
@import './loader';
@import './menu';
@import './buttons';
@import './header';

/*************************************
*** Feature
**************************************/

.feature {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 1.7em 1.9em 0.5em 2em;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: 220px;
    background: white;
    color: #252525;
    display: flex;

    &__body {
        width: 75%;
        padding-top: 5px;
        line-height: 1.2em;
    }

    &__icon {
        width: 25%;
        height: 80%;
        padding-right: 20px;
        padding-top: 5px;
    }

    &__title {
        font-weight: 500;
    }

    &__description {
        font-size: 0.7em;
        color: rgba(75, 75, 75, 0.9)
    }
}

/*************************************
*** Players
**************************************/

.players {
    background: white;
    background-image: linear-gradient(
        rgba(0,0,0,0.6),
        rgba(0, 0, 0, 0.6)),
        url('../img/players.jpg');
    background-size: cover;
    background-position: right;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    padding: 100px 0px;
    color: white;

    &__description {
        width: 50%;
        margin: 0 auto;
    }
}

/*************************************
*** FOOTER
**************************************/
footer {
    background: rgb(24, 24, 24);
    color: white;
    height: 150px;
    padding: 0px 0px;
    font-family: 'Rubik', sans-serif;
    font-size: .8em;
    font-weight: 500;
    text-transform: uppercase;
}

.footer-large {
    padding-top: 50px;
}

.copyright {
    font-size: .6em;
}

table {
    @include respond-to(handhelds) { font-size: 9px; }
}

