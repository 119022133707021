
/*************************************
*** PROFILE HEADER
**************************************/

header {
    background-image: linear-gradient(
        rgba(0,0,0,1),
        rgba(97, 157, 226, 0.1) 100%),
        url('../img/rink.jpg');
    background-size: cover;
    background-position: 10% 60%;
    height: 90vh;
}

.schedule-header {
    height: 50vh;
}

.welcome {
    color: $white;
    letter-spacing: 1px;
    padding-top: 33vh;
    text-align: center;

    &--schedule {
        padding-top: 18vh;
    }

    &__header {
        font-family: Roboto;
        font-weight: 300;
        .welcome__dash {
            @include respond-to(handhelds) { display: none; }
            @include respond-to(medium-screens) { display: none; }
        }
    }

    &__animation {
        color: $rd-blue;
        @include respond-to(handhelds) { display: none; }
        @include respond-to(medium-screens) { display: none; }
        overflow: hidden;
        border-right: .15em solid $rd-blue;
        white-space: nowrap;
        margin: 0 auto;
        animation: typing 3.5s steps(30, end), blink-caret 0.7s step-end infinite;
    }

    &__description {
        width: 60%;
        margin: 0 auto;
        margin-top: 25px;
        font-weight: 300;
        @include respond-to(handhelds) { font-size: 0.7em; width: 80%; }
        @include respond-to(medium-screens) { font-size: 0.7em; width: 80%; }
    }

    &__login {
        margin-top: 40px;
        @include respond-to(handhelds) { display: none; }
    }

    &__signup {
        display: block;
        font-size: .6em;
        margin-top: 12px;

        a {
            text-decoration: underline;
            color: $white;
            font-weight: 500;
        }
    }
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: $rd-blue; border-width: 2px; }
}
