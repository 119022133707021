/*************************************
*** Menu
**************************************/
.menu {
    height: 70px;
    color: $white;
    text-transform: uppercase;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition:all .5s;
    background: transparent;
    background-image: linear-gradient(rgba(0,0,0,0.1), transparent 100%);

    &--active {
        background-color: white;
        color: black;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        font-family: $font-logo;
        font-size: 35px;
        margin-top: 6px;
        @include respond-to(handhelds) { font-size: 30px; margin-top: 12px; margin-left: 10px;  }
        @include respond-to(medium-screens) { font-size: 30px; margin-top: 12px; margin-left: 10px; }
        @include respond-to(wide-screens) { font-size: 35px; }

        a {
            text-decoration: none;
        }

        &--light {
            a {
                color: $white;
            }
        }
        &--dark {
            a {
                color: black;
            }
        }
    }

    &__items {
        margin-top: 15px;

        ul {
            text-decoration: none;
        }

        li {
            display: inline-block;
        }
    }

    &__link {
        text-decoration: none;
        margin-right: 10px;
        font-size: 0.7em;
        letter-spacing: .5px;
        transition: all .5s ease;
        color: $off-white;

        &:hover {
            color: white;
            opacity: 1;
            text-decoration: none;
        }

        &--active {
            font-weight: 700;
        }
    }

    &__link--dark {
        color: rgba(0,0, 0, 0.7);
        &:hover {
            color: rgba(0,0, 0, 1);
        }
    }
    
    &__hamburger {
        position: absolute;
        top: 5px;
        right: 15px;
        margin-right: 15px;
        margin-top: 15px;
    }

    &__mobile-button{
        float: right;
        background: transparent;
        border: none;
    }
}


/*************************************
*** Mobile Menu
**************************************/
.mobile-menu{
    position: fixed;
    top: 20px;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    text-align: center;
    z-index: 9999;
    transition: all .3s ease;
    padding: 0;
    background-color: rgba(0,0,0,.8);
    opacity:0;

    &.active{
        height: 100%;
        width: 100%;
        top: 0;
        opacity: 1;
    }

    &__wrapper{
        height: 100%;
        overflow: auto;
        font-size: 14px;
        line-height: 20px;
        font-family: Roboto, monospace;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 60px;

        ul{
            padding: 30px 0;
            margin: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            min-height: 100%;
        }
    }

    &__item {
        list-style: none;
        padding: 6px 0;
    }
    
    &__link{
        color: #fff;
        display: inline-block;
        padding: 11px;
        position: relative;

        &:hover{
            opacity: 1;
            text-decoration: none;
        }

        &:after{
            background: #fff;
            bottom: 14px;
            right: 1px
        }
    }

    &__close{
        color: #fff;
        transition: all .3s;
        position: absolute;
        top: 25px;
        background-color: transparent;
        border: none;
    }
}